import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap'
import { State as ProfileStoreState } from 'reducers/user/viewProfile';

import { RouteComponentProps, withRouter } from 'react-router';

import 'styles/components/pages/viewProfile.scss';
import { Alerts, ErrorMessage } from '../../utils/alerts';
import { Profile } from '../../../types/Profile';
import {
  fetchProfile,
  fetchProfileItemsAndCollections,
  fetchProfileFavouriteItemsAndCollections,
} from '../../../actions/user/viewProfile';
import '../../../styles/components/pages/viewProfile.scss';
import { getCurrentAuthenticatedUser } from '../../utils/Auth';
import { Item } from 'types/Item';
import { toggle as itemModalToggle } from 'actions/modals/itemModal';
import { toggle as collectionModalToggle } from 'actions/modals/collectionModal';
import { Collection } from 'types/Collection';
import { debounce } from 'lodash';
import { viewProfileURL } from '../../../urls';
import { fetchProfileFavouriteStories } from '../../../actions/user/viewProfile';
import { WP_REST_API_Post, WP_REST_API_Posts } from 'wp-types';
import RollingHeader from 'components/layout/RollingHeader';

import './ViewProfile.scss'
import HighlightItem from 'components/highlight/HighlightItem';
import { CognitoUser } from 'amazon-cognito-identity-js';

interface Props extends RouteComponentProps, Alerts {
  fetchProfile: Function;
  profile: Profile;
  data: (Item | Collection)[];
  favouriteData: (Item | Collection)[];
  itemModalToggle: Function;
  collectionModalToggle: Function;
  isItemsAndCollectionsLoading: boolean;
  isFavouriteItemsAndCollectionsLoading: boolean;
  isFavouriteItemsAndCollectionsLoaded: boolean;
  fetchProfileFavouriteItemsAndCollections: Function;
  fetchProfileItemsAndCollections: Function;
  fetchProfileFavouriteStories: Function;
  fetchedAllItemsAndCollections: boolean;
  favouriteStories: WP_REST_API_Posts;
  isFavouriteStoriesLoading: boolean;
}

interface State {
  contributionsPage: number
  errorMessage: string
}

class ViewProfile extends React.Component<Props, State> {
  matchedId: string = '';

  constructor(props: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    super(props)
    this.state = {
      contributionsPage: 1,
      errorMessage: ''
    }
    this.handleInfiniteScroll = debounce(this.handleInfiniteScroll, 200);
  }

  componentDidMount() {
    this.initializeProfileData();
  }

  componentDidUpdate(prevProps: any ) { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (this.props.fetchedAllItemsAndCollections) {
      document.removeEventListener('scroll', this.handleInfiniteScroll);
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.initializeProfileData();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleInfiniteScroll);
  }

  initializeProfileData = async () => {
    // If we have an id from the URL pass it through, otherwise use the one from Redux State
    const matchedId = this.props.location.pathname.replace('/profiles/', '');

    const cognitoUser = await getCurrentAuthenticatedUser()

    if (matchedId) {
      await this.props.fetchProfile(matchedId, cognitoUser && (cognitoUser as CognitoUser).getUsername() || '');
      this.props.fetchProfileFavouriteItemsAndCollections();
      this.props.fetchProfileFavouriteStories();
      document.addEventListener('scroll', this.handleInfiniteScroll);
    } else {
      this.setState({ errorMessage: 'No profile with that id.' });
    }
  };

  handleInfiniteScroll = () => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - 200
    ) {
      this.props.fetchProfileItemsAndCollections();
    }
  };

  locationString(): string {
    const { city, country } = this.props.profile;
    if (city && city.length && !country) {
      return city;
    } else if (!city && country && country.length) {
      return country;
    } else if (city?.length && country?.length) {
      return `${city}, ${country}`;
    } else {
      return '—';
    }
  }

  render() {
    if (typeof this.props.profile === 'undefined') {
      return (
        <div id="viewProfile">
          <ErrorMessage message={this.props.errorMessage} />
        </div>
      );
    }
    const {
      full_name,
      position,
      affiliation,
      biography,
      website,
      field_expertise,
      profile_type,
      profile_image,
      city,
      country
      // public_profile
    } = this.props.profile;
    // console.log(this.props);

    const possibleFirstName = full_name?.split(' ')[0]

    const filteredData = this.props.data?.filter(it => !it.title?.startsWith('Banner')) || []
    const contributions = filteredData.slice(0, this.state.contributionsPage * 4)

    const isWebsiteValid = () => {
      try { 
        return Boolean(new URL(website as string))
      } catch (e){
        return null;
      }
    }

    return (
      <div className="profile-page">
        <ErrorMessage message={this.props.errorMessage} />

        { full_name && (
          <>
            <RollingHeader />

            <section className="profile-page__intro">
              <div className="profile-page__intro-picture">
                { profile_image &&
                  <img src={ profile_image as string } />
                }
              </div>
              <div className="profile-page__intro-details">
                <h1 className="profile-page__name">{ full_name }</h1>
                <p className="profile-page__subheadline">{ field_expertise ? field_expertise + ' | ' : ''}{ profile_type }</p>
                { (city || country) &&
                  <p className="profile-page__data">Location: { city + (country ? ' | ' + country : '') }</p>
                }
                { affiliation &&
                  <p className="profile-page__data">Affiliation: { affiliation }</p>
                }
                { position &&
                  <p className="profile-page__data">Position: { position }</p>
                }
                { isWebsiteValid() && 
                  <p className="profile-page__data"><a href={ website as string } target="_blank">Webiste</a></p>
                }
                { biography && 
                  <p className="profile-page__bio">{ biography }</p>
                }
              </div>
            </section>
          </>
        ) }

        { this.props.data?.length !== 0 &&
          <section className="profile-page__contributions background-rainbow">
            <h2 className="mb-4">〜 { possibleFirstName }'s contributions</h2>
            <Row className="profile-page__items">
              { contributions.map(it => (
                <Col xs="12" sm="6" md="3" className="px-1 mb-2" key={ it.id }>
                  { // @ts-ignore
                    <HighlightItem data={ it }/>
                  }
                </Col>
              )) }
            </Row>
            { contributions.length < this.props.data.length && (
              <div className="text-center">
                <button className="button mt-4" onClick={ () => this.setState({ contributionsPage: this.state.contributionsPage + 1 }) }>
                  Load more
                </button>
              </div>
            ) }
          </section>
        }

        { this.props.favouriteData?.length !== 0 &&
          <section className="profile-page__contributions background-white">
            <h2 className="mb-4">〜 { possibleFirstName }'s starred items</h2>
            <Row className="profile-page__items">
              { this.props.favouriteData.map(it => (
                <Col xs="12" sm="6" md="3" className="px-1 mb-2" key={ it.id }>
                  { // @ts-ignore
                    <HighlightItem data={ it }/>
                  }
                </Col>
              )) }
              <div />
              <div />
              <div />
            </Row>
            {/* { contributions.length < this.props.data.length && (
              <div className="text-center">
                <button className="button mt-4" onClick={ () => this.setState({ contributionsPage: this.state.contributionsPage + 1 }) }>
                  Load more
                </button>
              </div>
            ) } */}
          </section>
        }
      </div>
    );
  }
}

// State to props
const mapStateToProps = (state: { viewProfile: ProfileStoreState }) => {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  return {
    errorMessage: state.viewProfile.errorMessage,
    profile: state.viewProfile.profile,
    data: generateItemsAndCollectionsGrid(
      state.viewProfile.items,
      state.viewProfile.collections
    ),
    favouriteData: generateFavourites(
      state.viewProfile.favouriteItems,
      state.viewProfile.favouriteCollections,
      state.viewProfile.favouriteStories
    ),
    isFavouriteItemsAndCollectionsLoaded:
      state.viewProfile.isFavouriteItemsAndCollectionsLoaded,
    isItemsAndCollectionsLoading:
      state.viewProfile.isItemsAndCollectionsLoading,
    fetchedAllItemsAndCollections:
      !state.viewProfile.collectionsHasMore && !state.viewProfile.itemsHasMore,
    favouriteStories: state.viewProfile.favouriteStories,
    isFavouriteStoriesLoading: state.viewProfile.isFavouriteStoriesLoading
  };
};

// Connect our redux store State to Props, and pass through the fetchProfile function.
export default withRouter(
  connect(mapStateToProps, {
    fetchProfile,
    itemModalToggle,
    collectionModalToggle,
    fetchProfileItemsAndCollections,
    fetchProfileFavouriteItemsAndCollections,
    fetchProfileFavouriteStories,
  })(ViewProfile)
);

// memoize this if performance becomes a problem
const generateItemsAndCollectionsGrid = (
  items: Item[],
  collections: Collection[]
): (Item | Collection)[] => {
  let pendingCollections = [...collections];
  let pendingItems = [...items];
  let result: (Item | Collection)[] = [];
  while (pendingItems.length || pendingCollections.length) {
    result = [
      ...result,
      ...pendingCollections.splice(0, 3),
      ...pendingItems.splice(0, 3),
    ];
  }
  return result;
};

const generateFavourites = (
  items: Item[],
  collections: Collection[],
  stories: WP_REST_API_Posts
): (Item | Collection | WP_REST_API_Post)[] => {
  return [...items, ...collections, ...stories];
};
