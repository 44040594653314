import * as React from 'react';
import { connect } from 'react-redux';
import Embed from 'react-embed';

import { toggle } from 'actions/modals/liveStreamModal';
import { FaTimes } from 'react-icons/fa';
import { Col, Modal, Row } from 'reactstrap';

import 'styles/components/liveStreamModal.scss';

interface Props {
  open: boolean;
  stream: string;
  toggle: Function;
}

interface State {
  open: boolean;
  stream: string;
}

class LiveStreamModal extends React.Component<Props, State> {
  _isMounted;

  constructor(props: Props) {
    super(props);
    this._isMounted = false;

    this.state = {
      open: false,
      stream: ''
    };

  }

  async componentDidMount(): Promise<void> {
    this._isMounted = true;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (this._isMounted) {
      const state = {};

      if (this.props.open !== prevProps.open) {
        Object.assign(state, { open: this.props.open, stream: this.props.stream });
      }

      if (Object.keys(state).length > 0) {
        this.setState(state);
      }
    }
  }

  render() {
    const date = (new Date()).getTime() / 1000;
    console.log(date);

    if (this.state.open) {
      return (
        <Modal id="liveStreamModal" isOpen={this.state.open} backdrop toggle={() => this.props.toggle()}
               scrollable={false} centered>
          <button className="btn btn--plain closeButton">
            <FaTimes onClick={() => this.props.toggle()} size={ 24 } />
          </button>
          {date < 1656529200 ? 
            <iframe height="576" width="1024" src="https://ocean-archive.org/jwplayer.html" />
            :
          <Embed url={window.location.hostname.match(/staging/) || window.location.hostname.match(/localhost/) ? 'https://twitch.tv/acrossthecloud' : 'https://twitch.tv/oceanarchive'} />
      }
        </Modal>
      );
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = (state: { liveStreamModal: { open: boolean; stream: string } }) => ({
  open: state.liveStreamModal.open,
  stream: state.liveStreamModal.stream
});

export default connect(mapStateToProps, { toggle })(LiveStreamModal);
