import * as React from 'react'
import { API, Storage } from 'aws-amplify'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button, Input, Container, Spinner, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import { HiPlus, HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi'
import { BsTrash } from 'react-icons/bs'
import { v4 as uuidv4 } from 'uuid'

import { ErrorMessage, SuccessMessage } from 'components/utils/alerts'
import config from 'config'

import './Settings.scss'
import CarouselSettings, { CarouselTableData } from './CarouselSettings'
import NewsflashSettings, { NewsflashSettingsData } from './NewsflashSettings'

interface State {
  loading: boolean
  carouselTableData: CarouselTableData
  newsflashData: NewsflashSettingsData | null,
  errorMessage: string
  successMessage: string,
  activeTab: string
}

class Settings extends React.Component<RouteComponentProps, State> {
  carouselTableCols

  constructor(props: RouteComponentProps) {
    super(props)

    this.state = {
      loading: true,
      carouselTableData: [],
      newsflashData: null,
      errorMessage: '',
      successMessage: '',
      activeTab: 'carousel'
    }
  }

  async componentDidMount() {
    const settings = await API.get('tba21', 'admin/settings', {})
    this.setState(prev => ({
      ...prev,
      loading: false,
      carouselTableData: settings.home_carousel_items,
      newsflashData: settings.newsflash
    }))
  }

  doExport(contentType: string) {
    setTimeout(() => {
      this.setState(prev => ({ ...prev, errorMessage: 'Export failed, please try again.' }))
    }, 700)
  }

  render() {
    return (
      <Container className="admin-settings pt-3">
        { this.state.errorMessage && <ErrorMessage message={this.state.errorMessage} /> }
        { this.state.successMessage && <SuccessMessage message={this.state.successMessage} /> }

        {/* <h4 className="mt-5">Export content</h4>
        <div className="flex">
          <Button className="mr-2" onClick={ () => this.doExport('items') }>Items</Button>
          <Button className="mr-2" onClick={ () => this.doExport('collections') }>Collections</Button>
          <Button className="mr-2" onClick={ () => this.doExport('users') }>Users</Button>
        </div> */}

        <Nav className="mt-5 justify-center" tabs>
          <NavItem>
            <NavLink
              active={ this.state.activeTab === 'carousel' }
              onClick={() => this.setState({ activeTab: 'carousel' })}
            >
              Homepage Carousel
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={ this.state.activeTab === 'newsflash' }
              onClick={() => this.setState({ activeTab: 'newsflash' })}
            >
              Newsflash Banner
            </NavLink>
          </NavItem>
        </Nav>

        { !this.state.loading
            ? <TabContent activeTab={ this.state.activeTab }>
                <TabPane tabId="carousel">
                  <CarouselSettings
                    data={ this.state.carouselTableData }
                    onError={ msg => this.setState({ errorMessage: msg }) }
                    onSuccess={ msg => this.setState({ successMessage: msg }) }
                  />
                </TabPane>
                <TabPane tabId="newsflash">
                  <NewsflashSettings
                    data={ this.state.newsflashData }
                    onError={ msg => this.setState({ errorMessage: msg }) }
                    onSuccess={ msg => this.setState({ successMessage: msg }) }
                  />
                </TabPane>
              </TabContent>

            : <div className="text-center mt-5">
                <Spinner style={{ width: '5rem', height: '5rem' }} />
              </div>
        }
      </Container>
    )
  }
}

export default withRouter(Settings)
