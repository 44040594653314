import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import {
  loadMore,
  getAndPrepareCollection
} from 'actions/collections/viewCollection';
import { TiStar, TiStarOutline } from 'react-icons/ti';

import viewCollectionReducer from 'reducers/collections/viewCollection';
import {
  FETCH_COLLECTION_NO_MORE_TO_LOAD,
  FETCH_COLLECTION_LOAD_MORE,
  FETCH_COLLECTION_UPDATE_OFFSET,
  FETCH_COLLECTION_ERROR,
  FETCH_COLLECTION,
  FETCH_COLLECTION_ERROR_NO_SUCH_COLLECTION
} from 'reducers/collections/viewCollection';

import { toggleOverlay } from 'actions/loadingOverlay';

import { ErrorMessage } from '../utils/alerts';
import SpecialMenu from '../utils/SpecialMenu';
import { browser } from '../utils/browser';
import Share from '../utils/Share';
import moment from 'moment';
import 'styles/components/pages/viewItem.scss';
import { Item, Regions } from '../../types/Item';
import { Collection } from '../../types/Collection';
import { FilePreview } from '../utils/FilePreview';
import { debounce } from 'lodash';

import { createCriteriaOption } from '../search/SearchConsole';
import { toggle as collectionModalToggle } from '../../actions/modals/collectionModal';
import { toggle as itemModalToggle } from 'actions/modals/itemModal';
import { pushEntity as pushUserHistoryEntity } from '../../actions/user-history';
import {
  search as dispatchSearch,
  toggle as searchOpenToggle,
} from '../../actions/searchConsole';
import { UserHistoryState } from '../../reducers/user-history';
import HtmlDescription from '../utils/HtmlDescription';
import _ from 'lodash';
import DataLayout from 'components/utils/DataLayout';
import { itemURL, collectionURL, iframeCollectionEmbedCodeURL, iframeAudioCollectionEmbedCodeURL } from '../../urls';
import {
  addUserFavourite,
  deleteUserFavourite,
} from '../../actions/user/profile';
import { Profile } from '../../types/Profile';
import { ProfileState } from '../../reducers/user/profile';
import { getCurrentUserProfileById } from '../../actions/user/profile';
import { tagsToString } from 'components/metadata/Tags';
import { FaCode, FaExternalLinkAlt } from 'react-icons/fa';

import { modalToggle } from 'actions/pages/privacyPolicy'
import { GlobalState } from 'reducers/global'
import RollingHeader from 'components/layout/RollingHeader'
import ThemeSwitch from 'components/utils/ThemeSwitch'
import { SearchFilters } from '../../reducers/search'
import { getLicenceLink } from '../utils/licence'
import SearchItem from 'components/search/SearchItem'
import { RouteComponentProps, withRouter } from 'react-router';

import { ReactComponent as CitationIcon } from 'images/icons/citation.svg';

import config from 'config';
import { getStoriesAndTotalStoriesInDatabase } from 'REST/story';
import { WP_REST_API_Post } from 'wp-types';
import ItemPreview from 'components/item/ItemPreview';
import ItemGalleryModal from 'components/modals/ItemGalleryModal';
import AudioPlaylist from 'components/layout/audio/AudioPlaylist';
import { PlayerQueueState } from 'reducers/playerQueue';
import { selectQueueItem } from 'actions/playerQueue';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import logo from 'images/logo/oa_web_white.svg';
import PrettyAudio from 'components/layout/audio/PrettyAudio';


interface Props extends RouteComponentProps {
  dispatchLoadMore?: Function;
  parentReference?: Function;
  itemModalToggle: Function;
  collectionModalToggle: Function;
  searchOpenToggle: Function;
  dispatchSearch: Function;
  pushUserHistoryEntity: Function;
  addUserFavourite: Function;
  deleteUserFavourite: Function;
  favourites?:  Profile['favourites'];
  getCurrentUserProfile: Function;
  favouriteIsLoading: boolean;
  id: string;
  toggleOverlay: Function;
  activeTheme: string;
  modalToggle: Function;

  // ID string passed from the Parent that gives you the modal's body.
  modalBodyID?: string;
  userHistory?: UserHistoryState;
  playerQueue: PlayerQueueState
  selectQueueItem: Function

  embedded?: boolean
}

export interface State {
  data: (Item | Collection)[] | undefined;
  firstItem: Item | undefined;
  offset: number;
  errorMessage?: string;
  collection?: Collection;
  subItems?: Item[];
  subCollections?: Collection[];
  stories?: WP_REST_API_Post[];
  collectionModalToggled: boolean;
  collectionModalData?: Collection;
  dataRowID?: string;
  noMoreData: boolean;
  loading: boolean;
  isBurgerOpen: boolean;
  isVerticalLayout: boolean;
  wasRendered: boolean;
  descriptionOverflown: boolean;
  descriptionOpen: boolean;
  hasOnlyImages: boolean;
  hasOnlyAudio: boolean;
  galleryOpen: boolean;
  playlistModeActive: boolean;
  currentSubitem?: Item;
  currentPlayingSubitem?: Item;
}


class ViewCollection extends React.Component<Props, State> {
  browser: string
  _isMounted: boolean
  scrollDebounce
  modalBodyDiv
  descriptionDiv = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props);

    this._isMounted = false;
    const state = {
      data: undefined,
      firstItem: undefined,
      offset: 0,
      loading: false,
      noMoreData: false,
      collectionModalToggled: false,
      isBurgerOpen: false,
      isVerticalLayout: false,
      wasRendered: false,
      descriptionOverflown: false,
      descriptionOpen: false,
      hasOnlyImages: false,
      hasOnlyAudio: false,
      galleryOpen: false,
      playlistModeActive: false,
      currentSubitem: undefined
    };


    this.state = state;

    this.browser = browser();

    this.scrollDebounce = debounce(async () => await this.handleScroll(), 300);
  }

  dispatchLocal = (action) => {
    this.setState((prevState) => viewCollectionReducer(prevState, action));
  };

  loadCollection = async () => {

    const { id } = this.props;

    if (id) {

      this.props.toggleOverlay(true);
      try {

        const collection = await getAndPrepareCollection(id);

        if (!!collection) {
          this.dispatchLocal({
            type: FETCH_COLLECTION,
            collection: collection,
            noMoreData: undefined
          });

        } else {
          this.dispatchLocal({
            type: FETCH_COLLECTION_ERROR_NO_SUCH_COLLECTION,
            collection: undefined,
            items: {}
          });
        }
      } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        this.dispatchLocal({
          type: FETCH_COLLECTION_ERROR
        });
      } finally {
        this.props.toggleOverlay(false);
      }
    }

  };

  async componentDidMount(): Promise<void> {
    this._isMounted = true;

    this.setState({
      isVerticalLayout: window.innerWidth < 1140
    })

    window.addEventListener('resize', () => {
      this.setState(prev => ({ ...prev, isVerticalLayout: window.innerWidth < 1140 }))
    })
    
    await this.loadCollection();

    await this.pushCollectionToHistory();

    const { modalBodyID } = this.props;
    const { collection } = this.state;

    if (modalBodyID) {
      this.modalBodyDiv = document.getElementById(modalBodyID);
      this.modalBodyDiv.addEventListener('scroll', this.scrollDebounce, true);
      await this.loadData();
    } else {
      if (collection && this._isMounted) {
        this.setState(
          { dataRowID: `dataRow_${collection.id}_${Date.now()}` },
          async () => await this.loadData()
        );
      }
      window.addEventListener('scroll', this.scrollDebounce, true);
    }

    this.props.getCurrentUserProfile(0);
  }

  componentWillUnmount(): void {
    if (this.modalBodyDiv) {
      this.modalBodyDiv.removeEventListener(
        'scroll',
        this.scrollDebounce,
        false
      );
    } else {
      window.removeEventListener('scroll', this.scrollDebounce, true);
    }

    this._isMounted = false;
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): Promise<void> {
    if (!this._isMounted) {
      return;
    }

    if ((this.props.id && prevProps.id) && (prevProps.id !== this.props.id)) {
      this.setState({
        data: undefined,
        firstItem: undefined,
        subCollections: [],
        subItems: [],
        noMoreData: false,
        offset: 0
      }, async () => {
        await this.loadCollection();
        await this.pushCollectionToHistory(); 
        this.loadData();
      });
      
    }

    const updatedBrowseItemId = `${this.getBrowseItemFromURL()}`
    if ( this.state.currentSubitem && this.state.currentSubitem.id !== updatedBrowseItemId ) {
      const updatedCurrentSubitem = this.state.subItems?.find(it => it.id === updatedBrowseItemId)
      this.setState({ currentSubitem: updatedCurrentSubitem })
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    if ( !this.state.wasRendered && this.descriptionDiv.current ) {
      const isOverflown = this.descriptionDiv.current.scrollHeight > this.descriptionDiv.current.clientHeight + 5
      this.setState(prev => ({ ...prev, descriptionOverflown: isOverflown, wasRendered: true }))
    }

  }

  isFavourited() {
    const retVal = this.props.favourites?.collections?.includes(Number(this.state.collection?.id));
    return retVal;
  }

  async pushCollectionToHistory(prevCollection?: Collection): Promise<void> {
    const { collection } = this.state;
    if (collection !== undefined) {
      if (prevCollection !== undefined) {
        if (!_.isEqual(collection, prevCollection)) {
          const userHistoryEntity = await this.createHistoryEntity();
          this.props.pushUserHistoryEntity(userHistoryEntity);
        }
      } else {
        const userHistoryEntity = await this.createHistoryEntity();
        this.props.pushUserHistoryEntity(userHistoryEntity);
      }
    }
  }

  async createHistoryEntity(): Promise<Collection> {
    const { collection } = this.state;
    return {
      ...collection,
      __typename: 'collection',
    };
  }

  loadData = async () => {
    if (!this._isMounted || this.state.noMoreData) {
      return;
    }

    this.setState({ loading: true });
    const {offset} = this.state;
    const that = this;

      try {
        this.dispatchLocal({type: FETCH_COLLECTION_UPDATE_OFFSET, offset: offset + 100});
        await loadMore(this.props.id, offset, async (itemsAndCollections) => {
          const storiesData = this.state.collection?.stories && await getStoriesAndTotalStoriesInDatabase({ include: this.state.collection?.stories, sticky: false })
          const data = [ ...itemsAndCollections, ...(storiesData?.stories || []) ]

          if (data) {
            that.dispatchLocal({type: FETCH_COLLECTION_LOAD_MORE, datum: data});
          } else {
            that.dispatchLocal({type: FETCH_COLLECTION_NO_MORE_TO_LOAD});
          }

          that.setState(prevState => {
            const items = prevState.data
              ? (prevState.data.filter((d: Item | Collection | WP_REST_API_Post) => {
                // @ts-ignore
                return d.__typename === 'item' && !d.title?.startsWith('Banner');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              }) as any)
              : [];

            const collections = prevState.data
              ? (prevState.data.filter((d: Item | Collection | WP_REST_API_Post) => {
                return d.__typename === 'collection';
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              }) as any)
              : [];

            const stories = prevState.data
              ? (prevState.data.filter((d: Item | Collection | WP_REST_API_Post) => {
                return (d as WP_REST_API_Post).type === 'post';
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              }) as any)
              : [];

            const firstItem = prevState.data
              ? ((prevState.data
                .filter((d: Item | Collection) => {
                  return d.__typename === 'item';
                }) as Item[])
                .filter((d: Item ) => {
                  // return (
                  //   d.item_type === 'Image' ||
                  //   d.item_type === 'Video' ||
                  //   d.item_type === 'IFrame'
                  // );
                  return d.title?.startsWith('Banner');
                })[0] as Item)
              : undefined;

            const playing = (new URLSearchParams(window.location.search)).get('playing')
            const browseItem = this.getBrowseItemFromURL()

            const hasOnlyImages =
                !stories.length &&
                !collections.length &&
                !items.some(it => it.item_type !== 'Image')

            const hasOnlyAudio =
                !hasOnlyImages &&
                !collections.length &&
                !items.some(it => it.item_type !== 'Audio')

            return ({
              subItems: [...items],
              subCollections: [...collections],
              stories: [...stories],
              firstItem: firstItem,
              currentSubitem: items.length ? items.find(it => it.id === `${browseItem}`) : undefined,
              currentPlayingSubitem: (items.length && playing && items[playing]) || undefined,
              hasOnlyImages,
              hasOnlyAudio,
              playlistModeActive: hasOnlyAudio // enable playlist mode by default
            });

          });
          
        });
      } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        this.dispatchLocal({ type: FETCH_COLLECTION_ERROR, errorMessage: `${e}` });
      }


    if (this.state.collection && this.state.collection.id) {

      if (this._isMounted) {
        this.setState({ loading: false }, () => {
          if (this.scrollCheck()) {
            this.loadData();
          }
        });
      }
    }
  };

  collectionModalToggle = (collectionModalData: Collection) => {
    if (!this._isMounted) {
      return;
    }
    this.props.collectionModalToggle(true, collectionModalData);
  };

  /**
   * returns true for the bottom of the page or false for anywhere above the bottom
   */
  scrollCheck = (): boolean => {
    if (this.modalBodyDiv) {
      return (
        this.modalBodyDiv.scrollTop >=
        (this.modalBodyDiv.scrollHeight - this.modalBodyDiv.offsetHeight) / 1.7
      );
    } else {
      if (this.state.dataRowID) {
        const dataRowElement = document.getElementById(this.state.dataRowID);
        if (dataRowElement) {
          return (
            document.documentElement.scrollTop >=
            (document.body.offsetHeight - dataRowElement.offsetHeight) / 1.7
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  handleScroll = async () => {
    if (this.state.noMoreData) {
      return;
    }

    if (!this.state.loading && this.scrollCheck()) {
      await this.loadData();
    }
  };

  // @todo should be a util / dispatch
  onTagClick = (label: string, field: string) => {
    setTimeout(() => {
      this.props.itemModalToggle(false);
      this.props.collectionModalToggle(false);
      // this.props.searchOpenToggle(true);
      // this.props.dispatchSearch([createCriteriaOption(label, field)]);

      field === 'keyword_tag'
        ? this.props.history.push('/search', { searchString: label.replace('#', '') })
        : this.props.history.push('/search', { filters: { tags: label } as SearchFilters })
    });
  };

  onShowMoreClick = () => {
    this.setState(prev => ({ ...prev, descriptionOpen: true }))
  }

  onGalleryViewClick = () => {
    this.setState(prev => ({ ...prev, galleryOpen: true }))
  }

  getSubitemDescription = () => {
    if (!this.state.currentSubitem?.description) return ''

    const helperEl = document.createElement('div')
    helperEl.innerHTML = this.state.currentSubitem.description.replace(/<(\/?mark)>/g, '%%$1%%')
    const plainDescription = helperEl.innerText.replace(/%%(\/?mark)%%/g, '<$1>')

    return plainDescription.substring(0, 800)
  }

  getSubitemMeta = () => {
    const metaItems = [
      ...this.state.currentSubitem?.creators || [],
      this.state.currentSubitem?.year_produced || ( this.state.currentSubitem?.time_produced && new Date(this.state.currentSubitem?.time_produced).getFullYear() ) || ''
    ]
    return metaItems.join(', ')
  }

  getBrowseItemFromURL = () => {
    const browse = (new URLSearchParams(window.location.search)).get('browse') || '0'
    return parseInt(browse, 10)
  }

  // playAudio = id => {
  //   this.props.selectQueueItem()
  // }

  render() {
    if (typeof this.state.collection === 'undefined') {
      return (
        <div id="item" className="view-item pt-5">
          <div className="mt-5"><ErrorMessage message={this.state.errorMessage} /></div>
        </div>
      )
    }
    const {
      id,
      creators,
      title,
      poster,
      description,
      license,
      aggregated_concept_tags,
      aggregated_keyword_tags,

      time_produced,
      year_produced,
      venues,
      exhibited_at,
      url,
      regions,
      copyright_holder,
      stage_link,
      stage_link_caption,
      items_first
    } = this.state.collection;

    if (this.props.userHistory && this.props.userHistory.loading) {
      return <></>;
    }

    if (description) {
      const metadescription = document.querySelector('meta[name=\'description\']');
      metadescription!.setAttribute('content', description);
    }
    if (aggregated_concept_tags && aggregated_concept_tags.length > 0) {
      const keywords = document.querySelector('meta[name=\'keywords\']');
      let keyword_list = tagsToString(aggregated_concept_tags);
      if (aggregated_keyword_tags && aggregated_keyword_tags.length > 0) {
        keyword_list += ', ' + tagsToString(aggregated_keyword_tags);
      }
      keywords!.setAttribute('content', keyword_list);
    }
    if (title) {
      const docTitle = document.querySelector('title');
      docTitle!.innerHTML = title;
    }

    const isBrowseMode = (new URLSearchParams(window.location.search)).get('browse') !== null
    const currentSubitemIndex = this.state.currentSubitem && this.state.subItems?.findIndex(it => it.id === this.state.currentSubitem?.id) || 0

    console.log(currentSubitemIndex);
    
    const Metadata = () => (
      <div className="view-item__meta">
        { creators &&
          creators.join(', ')
        }
        { time_produced ?
            <p>Date Produced: { moment(time_produced, moment.defaultFormatUtc).format('Do MMMM YYYY') }</p>
          : year_produced &&
            <p>Year Produced: { year_produced }</p>
        }
        { venues?.length &&
          <p>{ venues.length > 1 ? 'Publication Venue' : 'Publication Venues' }: { venues.join(', ') }</p>
        }
        { exhibited_at?.length &&
          <p>Exhibited At: { exhibited_at.join(', ') }</p>
        }
        { regions?.length &&
          <p>{ regions.length > 1 ? 'Regions' : 'Region' }: { regions.map((region) => Regions[region]).join(', ') }</p>
        }
        { license && (
          <p>License: {
            getLicenceLink(license)
              ? <a href={ getLicenceLink(license) || '' } target="_blank" rel="noopener noreferrer">{ license }</a>
              : <a href='#' onClick={() => this.props.modalToggle('RL_MODAL', true)}>Ocean Archive</a>
          }</p>
        ) }
        { copyright_holder &&
          <p>Copyright Owner: { copyright_holder }</p>
        }
        { url &&
          <p>Relation: <a href={ url } target="_blank" rel="noreferrer noopener">Click to view</a></p>
        }
        { aggregated_concept_tags?.length !== 0 && (
          <div className="view-item__concept-tags mt-4">
            <p>Concept Tags</p>
            <div>
              {aggregated_concept_tags?.map((t) => {
                return (
                  <Button
                    className="page-link tag d-inline-block text-left"
                    key={t.tag_name}
                    onClick={() =>
                      this.onTagClick(t.tag_name, 'concept_tag')
                    }
                  >
                    #{t.tag_name}
                  </Button>
                );
              })}
            </div>
          </div>
        ) }
        { aggregated_keyword_tags?.length !== 0 && (
          <div className="view-item__keyword-tags mt-3">
            <p>Keyword Tags</p>
            <div>
              {aggregated_keyword_tags?.map((t) => {
                return (
                  <Button
                    className="ml-1 tag d-inline-block text-left"
                    key={t.tag_name}
                    onClick={() =>
                      this.onTagClick(t.tag_name, 'keyword_tag')
                    }
                  >
                    #{t.tag_name}
                  </Button>
                );
              })}
            </div>
          </div>
        ) }
        { stage_link && (
          <div className="view-item__stage-link mt-4">
            <p>{ stage_link_caption }</p>
            <a href={ stage_link } target="_blank" className="button button--stage button--small">
              <span>tba 21 on st_age</span>
            </a>
          </div>
        ) }
        <div className="row">
          <SpecialMenu id={id} />
        </div>
      </div>
    )

    const SubCollections = () => (
      <>
        { this.state.subCollections && this.state.subCollections.length !== 0 && (
          this.state.subCollections.map((collection: Collection, i) => (
            // @ts-ignore
            <SearchItem data={{ ...collection, record_type: 'collection', objectID: '0' }} key={`col_${collection.id}`} noTags filesFetched />
          ))
        ) }
      </>
    )

    const SubItems = () => (
      <>
        { this.state.subItems && this.state.subItems.length !== 0 && (
          this.state.subItems
            .filter((item: Item) => {
              if (this.state.firstItem) {
                return item.id !== this.state.firstItem.id;
              } else {
                return true;
              }
            })
            .map((item: Item) => (
              <SearchItem
                // @ts-ignore
                data={{ ...item, record_type: 'item', objectID: '0' }}
                key={ `item_${item.id}` }
                focused={ isBrowseMode && item.id === this.state.currentSubitem?.id }
                link={ 
                  // @ts-ignore
                  this.state.subItems.length > 1
                    ? collectionURL(this.state.collection?.id || '') + '?browse=' + item.id
                    : undefined
                }
                // @ts-ignore
                openInNewTab={ !isBrowseMode && this.state.subItems.length > 1 }
                noTags
                filesFetched
              />
            ))
        ) }
      </>
    )

    const Stories = () => (
      <>
        { this.state.stories && this.state.stories.length !== 0 && (
          this.state.stories.map((story: WP_REST_API_Post, i) => {
            const thumb = (story as any)._embedded['wp:featuredmedia']?.[0]?.media_details.sizes.medium_large
            const data = {
              ...story,
              title: story.title.rendered,
              description: story.excerpt?.rendered.replace(/\n/g, '') || '',
              thumbnails: thumb ? { 720: thumb.source_url } : null,
              record_type: 'story',
              objectID: '0'
            }
            // @ts-ignore
            return <SearchItem data={ data } key={`story_${story.id}`} noTags filesFetched />
          })
        ) }
      </>
    )

    const cls = classNames('view-item', { embedded: this.props.embedded })

    return (
      <div id="item" className={ cls }>
        { !this.props.embedded && (
          <RollingHeader dark={ this.props.activeTheme === 'light' } />
        ) }

        <ErrorMessage message={this.state.errorMessage} />

        <div className="view-item__body">
          {/* ======== LEFT ======== */}
          { !this.props.embedded && (
            <div className="view-item__left">
              <div>
                {/* ======== Details ======== */}
                <h1 className="view-item__title">{title}</h1>
                { !this.state.isVerticalLayout &&
                  <Metadata />
                }
              </div>
            </div>
          ) }

          {/* ======== RIGHT ======== */}
          <div className="view-item__right">

            {/* ======== Preview ======== */}
            { !this.props.embedded && (
              <div className="view-item__preview">
                {
                  !isBrowseMode &&
                  !this.state.currentPlayingSubitem &&
                  !this.props.playerQueue.currentItem && (
                    poster
                      ? <img src={ config.urls.BASE_CONTENT_URL + poster } />
                      : this.state.firstItem && (
                          this.state.firstItem.item_type === 'IFrame' ? (
                            <DataLayout
                              data={this.state.firstItem}
                              key={`item_${this.state.firstItem.id}`}
                              itemModalToggle={this.props.itemModalToggle}
                              collectionModalToggle={this.collectionModalToggle}
                              firstItem={true}
                            />
                          ) : (
                            <FilePreview file={this.state.firstItem.file} isHeader={true} />
                          )
                        )
                  )
                }
                {
                  isBrowseMode && this.state.currentSubitem && (
                    <>
                      <ItemPreview item={ this.state.currentSubitem } />
                      { this.state.subItems && this.state.subItems.length > 1 && (
                        <div className="view-item__arrows">
                          { currentSubitemIndex > 0 && (
                            <Link
                              className="arr-left button button--circle-desktop-only"
                              to={ collectionURL(this.state.collection?.id || '') + '?browse=' + this.state.subItems[currentSubitemIndex - 1].id }
                            >
                              <span>&lt;</span><span>Previous</span>
                            </Link>
                          ) }
                          { currentSubitemIndex < this.state.subItems.length - 1 && (
                            <Link
                              className="arr-right button button--circle-desktop-only"
                              to={ collectionURL(this.state.collection?.id || '') + '?browse=' + this.state.subItems[currentSubitemIndex + 1].id }
                            >
                              <span>&gt;</span><span>Next</span>
                            </Link>
                          ) }
                        </div>
                      ) }
                    </>
                  )
                }
                {
                  this.props.playerQueue.currentItem && (
                    <ItemPreview
                      item={ this.props.playerQueue.currentItem }
                      key={ this.props.playerQueue.currentItem.id }
                      autoplay
                    />
                  )
                }
              </div>
            ) }

            {/* ======== Description ======== */}
            { !this.props.embedded && (
              <>
                <div ref={ this.descriptionDiv } className={ `view-item__description${ this.state.descriptionOpen && ' open' || '' }` }>
                  <div>
                    { isBrowseMode
                      ? <div>
                          <h3 className='view-item__subitem-title'>
                            <a href={ itemURL(this.state.currentSubitem?.id || '') } target="_blank">{ this.state.currentSubitem?.title }</a>
                          </h3>
                          <p className='view-item__subitem-meta' dangerouslySetInnerHTML={{ __html: this.getSubitemMeta() }} />
                          <p className='view-item__subitem-description' dangerouslySetInnerHTML={{ __html: this.getSubitemDescription() }} />
                        </div>
                      : <div className={ `view-item__description-text${ this.state.descriptionOverflown && ' overflown' || '' }` }>
                          { description && (
                            <HtmlDescription description={description} />
                          ) }
                        </div>
                    }
                  </div>
                  { this.state.descriptionOverflown && !this.state.descriptionOpen && (
                    <button className="view-item__description-more button" onClick={ this.onShowMoreClick }>
                      Read more
                    </button>
                  ) }
                  { isBrowseMode && (
                    <Link className="button mt-3" to={ itemURL(this.state.currentSubitem?.id || '') }>
                      Open item
                    </Link>
                  ) }
                </div>

                {/* ======== Actions ======== */}
                <div className="view-item__actions">
                  <div className="view-item__actions-first-row">
                    <div className="view-item__actions-favourite">
                      { !this.props.favouriteIsLoading &&
                        <button className="btn btn--plain">
                          { this.isFavourited()
                            ? <TiStar
                                color="#50E2C1"
                                title='Remove from favourites'
                                onClick={() => {
                                  this.props.deleteUserFavourite('items', Number(id));
                                }}
                              />
                            : <TiStarOutline
                                color="#50E2C1"
                                title='Add to favourites'
                                onClick={() => {
                                  this.props.addUserFavourite('items', Number(id));
                                }}
                              />
                          }
                        </button>
                      }
                    </div>
                    <div className="view-item__actions-citation">
                      <Share
                        color={ this.props.activeTheme === 'light' ? 'dark-gray' : 'white' }
                        variant="citation"
                        iconComponent={<CitationIcon />}
                        authors={ creators }
                        title={ title }
                        published={ year_produced || time_produced }
                        help='Copy citation'
                      />
                    </div>
                    { id && (
                      <>
                        <div className="view-item__actions-embed">
                          <Share
                            color={ this.props.activeTheme === 'light' ? 'dark-gray' : 'white' }
                            variant="fullText"
                            iconComponent={<FaCode />}
                            text={ this.state.hasOnlyAudio
                              ? iframeAudioCollectionEmbedCodeURL(id, title || '')
                              : iframeCollectionEmbedCodeURL(id, title || '')
                            }
                            help='Copy embed code'
                          />
                        </div>
                        <div className="view-item__actions-share">
                          <Share
                            color={ this.props.activeTheme === 'light' ? 'dark-gray' : 'white' }
                            variant="prefixedWithHostname"
                            text={collectionURL(id)}
                            help='Copy link'
                          />
                        </div>
                      </>
                    ) }
                    <ThemeSwitch />
                  </div>
                </div>

                { this.state.isVerticalLayout &&
                  <Metadata />
                }
              </>
            ) }

            {/* ======== Collection subitems ======== */}
            { this.props.embedded
                ? <>
                    <div>
                      <a href={'https://ocean-archive.org/collection/' + id} target="_blank" rel="noreferrer noopener" className="d-flex justify-content-sm-between flex-column-reverse flex-sm-row">
                        <h2 className="text-subtitle mb-0 mr-2">{ title }</h2>
                        <img src={logo} id="embed_logo" alt="Ocean Archive logo" />
                      </a>
                      {/* <a id="open_link" href={'https://ocean-archive.org/collection/' + id} target="_blank" rel="noreferrer noopener"><FaExternalLinkAlt size={18}/></a> */}
                    </div>
                    <div className="overflow-auto hidden-scrollbar mt-2 pt-2" style={{ flex: '1' }}>
                      <AudioPlaylist
                        items={ this.state.subItems }
                        currentItem={ this.state.currentPlayingSubitem?.id }
                        baseUrl={ collectionURL(this.state.collection.id || '') }
                        onSelect={ itemId => {
                          // this.playAudio(itemId)
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                        } }
                        compact
                      />
                    </div>
                    { this.props.playerQueue.currentItem && (
                      <div style={{ height: '90px' }}>
                        <PrettyAudio
                          data={{
                            id: `${id}_slider`,
                            title: this.props.playerQueue.currentItem.title || '',
                            url: this.props.playerQueue.currentItem.file.url,
                            s3_key: this.props.playerQueue.currentItem.s3_key,
                            isCollection: false
                          }}
                          key={ this.props.playerQueue.currentItem.id }
                          inEmbedMode
                          autoplay
                        />
                      </div>
                    ) }
                  </>

                : <div className="w-100 pt-3">
                    <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
                      { this.state.subItems?.length &&
                        <h2 className="mt-3">Collection items</h2>
                      }
                      { this.state.hasOnlyImages &&
                        <button className="button desktop-only" onClick={ this.onGalleryViewClick }>
                          Gallery view
                        </button>
                      }
                      { this.state.hasOnlyAudio &&
                        <button
                          className="button desktop-only"
                          onClick={ () => this.setState({ playlistModeActive: !this.state.playlistModeActive }) }
                        >
                          { this.state.playlistModeActive ? 'Item view' : 'Playlist view' }
                        </button>
                      }
                    </div>

                    { this.state.playlistModeActive
                      ? <AudioPlaylist
                          items={ this.state.subItems }
                          currentItem={ this.state.currentPlayingSubitem?.id }
                          baseUrl={ collectionURL(this.state.collection.id || '') }
                          onSelect={ itemId => {
                            // this.playAudio(itemId)
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                          } }
                        />
                      : <>
                          <div className="view-item__subitems">
                            { items_first
                              ? <>
                                  <SubItems />
                                  <SubCollections />
                                  <Stories />
                                </>
                              : <>
                                  <SubCollections />
                                  <SubItems />
                                  <Stories />
                                </>
                            }

                            {/* Dummy items for last row alignment */}
                            <div className="search-results__item" />
                            <div className="search-results__item" />
                            <div className="search-results__item" />
                            <div className="search-results__item" />
                            <div className="search-results__item" />
                            <div className="search-results__item" />
                          </div>
                        </>
                    }

                    { this.state.hasOnlyAudio && !this.props.embedded &&
                      <button
                        className="button button--small mobile-only mx-auto d-flex mt-4"
                        onClick={ () => this.setState({ playlistModeActive: !this.state.playlistModeActive }) }
                      >
                        { this.state.playlistModeActive ? 'Switch to item view' : 'Switch to playlist view' }
                      </button>
                    }
                  </div>
            }
          </div>
        </div>

        <ItemGalleryModal items={ this.state.subItems || [] } open={ this.state.galleryOpen } onToggle={ () => this.setState({ galleryOpen: false }) } />
      </div>
    );
  }
}

// State to props
const mapStateToProps = (
  state: { 
    userHistory: UserHistoryState; 
    profile: ProfileState;
    global: GlobalState;
    playerQueue: PlayerQueueState
  },
  ownProps: { 
    modalBodyID?: string; 
    id: string; // the collection ID that this component loads and shows.
  }
) => {
  return {
    id: ownProps.id,
    modalBodyID: ownProps.modalBodyID,
    userHistory: state.userHistory,
    favouriteIsLoading: state.profile.favouriteIsLoading,
    favourites: state.profile?.details?.favourites,
    activeTheme: state.global.theme,
    playerQueue: state.playerQueue
  };
};


export default connect(mapStateToProps, {
    modalToggle,
    collectionModalToggle,
    itemModalToggle,
    pushUserHistoryEntity,
    searchOpenToggle,
    dispatchSearch,
    addUserFavourite,
    deleteUserFavourite,
    toggleOverlay,
    selectQueueItem,
    getCurrentUserProfile: getCurrentUserProfileById
  })(withRouter(ViewCollection));
