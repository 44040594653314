import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Router, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { has } from 'lodash';

import history from './history';
import store from './store';
import Header from 'components/layout/Header';

import { DEFAULT_THEME } from 'reducers/global'

import {
  Home,

  // START ADMIN
  ViewItem,
  EmbedItem,
  AdminManageUsers,
  AdminSettings,

  // START Tables
  AdminCollections,
  AdminItems,
  // END Tables

  // END ADMIN

  // START Contributors
  Items,
  CollectionEditor,
  // END Contributors

  // START USER
  Profile,
  Login,
  SignUp,
  ResetPassword,
  AccountConfirmation,
  MapView,
  // END USER
} from './components/';

import { AuthConsumer, AuthProvider } from './providers/AuthProvider';
import SearchConsole from './components/search/SearchConsole';
import { NotFound404Message } from './components/utils/alerts';
import Announcements from './components/admin/pages/announcements/Announcements';
import { AnnouncementEditor } from './components/metadata/AnnouncementEditor';
import ViewProfile from './components/user/profile/ViewProfile';
import ViewCollectionContainer from './components/collection/ViewCollectionContainer';
import LoadingOverlay from './components/LoadingOverlay';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsAndConditions from './components/pages/TermsAndConditions';
import RestrictiveLicence from './components/pages/RestrictiveLicence';
import ItemModal from './components/modals/ItemModal';
import CollectionModal from './components/modals/CollectionModal';
import LiveStreamModal from './components/modals/LiveStreamModal';
import About from './components/pages/About';
import HelpArchiveModal from './components/modals/HelpArchiveModal';
import HelpStoriesModal from './components/modals/HelpStoriesModal';
import {
  viewProfileURL,
  itemURL,
  collectionURL,
  storyURL,
  embedCollectionURL,
} from './urls';
import ViewStory from 'components/story/ViewStory';
import Stories from 'components/pages/Stories';
import ScrollToTop from './components/ScrollToTop';
import Footer from 'components/layout/Footer'
import InfoPageWrapper from 'components/InfoPageWrapper'
import ViewWebArchive from 'components/webarchive/ViewWebArchive'

import Home23 from 'components/pages/Home'; 
import Search from 'components/pages/Search'; 

// eslint-disable-next-line react/prop-types
const LoggedInRoutes = ({ isAuthenticated, ...rest }) => { 
  const isLoggedIn = isAuthenticated;
  return (
    <Route
      exact
      path="/Profile"
      render={(routeProps) =>
        isLoggedIn ? (
          <div className="main editor pb blue">
            <Profile {...history} {...routeProps} {...rest} />
          </div>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

// eslint-disable-next-line react/prop-types
const ContributorsRoutes = ({ authorisation, ...rest }) => {
  const hasAuth =
    has(authorisation, 'contributor') ||
    has(authorisation, 'editor') ||
    has(authorisation, 'admin');
  return (
    <>
      <Switch>
        <Route
          exact
          path="/contributor/items/add"
          render={(routeProps) =>
            hasAuth ? (
              <div className="main editor pb">
                <Items {...history} {...routeProps} {...rest} />
              </div>
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          exact
          path="/contributor/items/:id?"
          render={(routeProps) =>
            hasAuth ? (
              <div className="main editor pb">
                <AdminItems {...routeProps} {...rest} />
              </div>
            ) : (
              <Redirect to="/" />
            )
          }
        />
      </Switch>

      <Switch>
        <Route
          exact
          path="/contributor/collections/add"
          render={(routeProps) =>
            hasAuth ? (
              <div className="main editor pb">
                <CollectionEditor
                  editMode={false}
                  {...history}
                  {...routeProps}
                  {...rest}
                />
              </div>
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          exact
          path="/contributor/collections/:id?"
          render={(routeProps) =>
            hasAuth ? (
              <div className="main editor pb">
                <AdminCollections {...routeProps} {...rest} />
              </div>
            ) : (
              <Redirect to="/" />
            )
          }
        />
      </Switch>

      <Route
        exact
        path="/contributor/announcements"
        render={() =>
          hasAuth ? (
            <div className="main editor pb">
              <Announcements {...rest} />
            </div>
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/contributor/announcements/add"
        render={() =>
          hasAuth ? (
            <div className="main editor pb">
              <AnnouncementEditor
                editMode={false}
                path={'/contributor/announcements/add'}
                {...rest}
              />
            </div>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    </>
  );
};

// eslint-disable-next-line react/prop-types
const AdminRoutes = ({ authorisation, ...rest }) => {
  const isAdmin = has(authorisation, 'admin');
  return (
    <>
      <Route
        exact
        path="/admin/settings"
        render={(routeProps) =>
          isAdmin ? (
            <div className="main admin pb theme-colors">
              <AdminSettings {...routeProps} {...rest} />
            </div>
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/admin/ManageUsers"
        render={(routeProps) =>
          isAdmin ? (
            <div className="main admin pb">
              <AdminManageUsers {...routeProps} {...rest} />
            </div>
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/admin/Collections/:id?"
        render={(routeProps) =>
          isAdmin ? (
            <div className="main admin pb">
              <AdminCollections {...routeProps} {...rest} />
            </div>
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/admin/Items/:id?"
        render={(routeProps) =>
          isAdmin ? (
            <div className="main admin pb">
              <AdminItems {...routeProps} {...rest} />
            </div>
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route
        exact
        path="/admin/announcements"
        render={() =>
          isAdmin ? (
            <div className="main admin pb">
              <Announcements {...rest} />
            </div>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    </>
  );
};

// eslint-disable-next-line react/prop-types
const NoMatch = ({ location }) => {
  // eslint-disable-next-line react/prop-types
  return location.pathname.match(/(\/admin\/|\/contributor|\/Profile)/i) ? (
    <></>
  ) : (
    // eslint-disable-next-line react/prop-types
    <NotFound404Message pathName={location.pathname} />
  );
};

export const AppRouter = () => {
  const [isOnboardingShown, setIsOnboardingShown] = React.useState(true);
  return (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop />
        <AuthProvider>
          <div id="body" className={ `theme-${DEFAULT_THEME}` }>
            <Route
              path="/"
              render={({ location }) => {
                const isStory =
                  location.pathname.includes(storyURL('')) ||
                  location.pathname === '/';
                const isHomepage = location.pathname === '/home';
                const isSearch = location.pathname === '/search';
                return !location.pathname.startsWith('/embed') && !location.pathname.startsWith('/webarchive') ? (
                  <>
                    {/* <Header fixed={isStory || isHomepage || isSearch} /> */}
                    <Header fixed />
                    <SearchConsole />
                    {/* {window === window.top ? (<>
                      <PrivacyPolicyPopUp
                        setIsOnboardingShown={setIsOnboardingShown}
                      />
                      {!isOnboardingShown && (
                        <StoryOnboardingPopup
                          isOnboardingShown={isOnboardingShown}
                          setIsOnboardingShown={setIsOnboardingShown}
                        />
                      )}</>
                    ) : <></> } */}
                    <RestrictiveLicence />
                    <HelpArchiveModal />
                    <HelpStoriesModal />
                    <ItemModal />
                    <CollectionModal />
                    <LiveStreamModal />
                    <LoadingOverlay />
                  </>
                ) : (
                  <></>
                );
              }}
            />

            <Switch>
              <Route exact path="/" component={ Home23 } />
              <Route path="/archive" render={ () => <Redirect to={'/'} /> } />
              <Route exact path="/live" component={ Home23 }/>
              <Route path="/search" component={ Search } />

              <Route path="/stories" render={ () => <Redirect to={'/journeys'} /> } />
              <Route path="/journeys" component={ Stories } />

              <Route
                path="/about"
                render={() => (
                  <InfoPageWrapper>
                    <About />
                  </InfoPageWrapper>
                )}
              />
              <Route
                path="/terms-and-conditions"
                render={() => (
                  <InfoPageWrapper>
                    <TermsAndConditions />
                  </InfoPageWrapper>
                )}
              />
              <Route
                path="/privacy-policy"
                render={() => (
                  <InfoPageWrapper>
                    <PrivacyPolicy />
                  </InfoPageWrapper>
                )}
              />

              <Route
                path={itemURL(':id')}
                render={() => (
                  <>
                    <div className="main pb theme-colors">
                      <ViewItem />
                    </div>
                    <Footer />
                  </>
                )}
              />
              <Route
                path="/embed/:id"
                render={() => (
                  <div className="main pb theme-colors">
                    <EmbedItem />
                  </div>
                )}
              />
              <Route
                path="/embed-collection/:id"
                render={() => (
                  <div className="main pb theme-colors">
                    <ViewCollectionContainer embedded />
                  </div>
                )}
              />
              <Route
                path={embedCollectionURL(':id')}
                render={() => (
                  <div className="main pb theme-colors">
                    <ViewCollectionContainer />
                  </div>
                )}
              />
              <Route
                path={collectionURL(':id')}
                render={() => (
                  <div className="main pb theme-colors">
                    <ViewCollectionContainer />
                  </div>
                )}
              />
              <Route
                path={viewProfileURL(':profileId')}
                render={() => (
                  <div className="main d-flex flex-column">
                    <ViewProfile />
                    <div className="flex-fill" />
                    <Footer />
                  </div>
                )}
              />

              <Route
                path="/webarchive/:id"
                render={() => (
                  <div className="main d-flex theme-colors">
                    <ViewWebArchive />
                  </div>
                )}
              />

              <Route exact path="/map" component={MapView} />

              <Route exact path="/login" component={Login} />
              <Route exact path={storyURL(':slug')} component={ViewStory} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/resetPassword/" component={ResetPassword} />

              <Route
                exact
                path="/confirm/:email"
                component={AccountConfirmation}
              />

              <AuthConsumer>
                {({ isLoading }) => {
                  if (!isLoading) {
                    return <Route component={NoMatch} />;
                  } else {
                    return <></>;
                  }
                }}
              </AuthConsumer>
            </Switch>
            <AuthConsumer>
              {({ isLoading, authorisation, isAuthenticated }) => {
                if (!isLoading) {
                  return (
                    <>
                      <AdminRoutes
                        authorisation={authorisation}
                        history={history}
                      />
                      <ContributorsRoutes
                        authorisation={authorisation}
                        history={history}
                      />
                      <LoggedInRoutes
                        isAuthenticated={isAuthenticated}
                        history={history}
                      />

                      {!isAuthenticated ? (
                        <Route
                          exact
                          path="/admin"
                          render={() => <Redirect to={'/login'} />}
                        />
                      ) : (
                        <Route
                          exact
                          path="/admin"
                          render={() => <Redirect to={'/admin/Items'} />}
                        />
                      )}

                      {!isAuthenticated ? (
                        <Route
                          exact
                          path="/contributor"
                          render={() => <Redirect to={'/login'} />}
                        />
                      ) : (
                        <Route
                          exact
                          path="/contributor"
                          render={() => <Redirect to={'/contributor/items'} />}
                        />
                      )}
                    </>
                  );
                } else {
                  return <></>;
                }
              }}
            </AuthConsumer>
          </div>
        </AuthProvider>
      </Router>
    </Provider>
  );
};
