export default {
  s3: {
    REGION: 'eu-central-1',
    BUCKET: 'ocean-archive-prod-content',
    PROFILE_PIC_BUCKET: 'profile-pics.ocean-archive.org'
  },
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://demo-api.ocean-archive.org/'
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_MClQ7jNon',
    APP_CLIENT_ID: '2lrll2fm15mhbhjgmss7snk8v6',
    IDENTITY_POOL_ID: 'eu-central-1:87c9ec1d-7fa8-4c74-b907-c31bdec2b879'
  },
  // google : {
  //   UA_ID: 'GTM-TX27ZNM'
  // },
  social: {
    FB: '370805207078211'
  },
  urls: {
    BASE_CONTENT_URL: 'https://prod-content.ocean-archive.org/',
    THUMBNAIL_URL: 'https://thumbnails.ocean-archive.org/',
    VIDEO_STREAMING_URL: 'https://video-streaming.ocean-archive.org/',
    AUDIO_URL: 'https://audio.ocean-archive.org/',
    PROFILE_URL: 'https://profile-pics.ocean-archive.org/',
    WP_URL: 'https://dev-site-strs.ocean-archive.org/'
  },
  auth: {
    TWITCH_USER: 'oceanarchive',
    TWITCH_CLIENT_ID: '6p0coxhy06i38fk5dwlwvara8cqrhc',
    TWITCH_BEARER_TOKEN: 'hvvi2mp6mt2fc1rinc652vhnr6iuj3',
    VIMEO_BEARER_TOKEN: 'dd8a11e339008719ce5bb81216b4fe2b',
    GOOGLE_API_KEY: 'AIzaSyDGkduYFuHND60YPMN6QW91d-WFF5rDSmo'
  },
  WP: {
    PREVIEW_CATEGORY_ID: 491
  },
  algolia: {
    APP_ID: '3SN8JU614E',
    SEARCH_API_KEY: '9709c7498543e27c076018e00be4ac4a'
  }
}
