import { useEffect, useRef, memo } from 'react'
import { spline, Point } from '../../lib/spline'
import { createNoise2D } from 'simplex-noise'
import classnames from 'classnames'

import './Blob.scss'

const noise2D = createNoise2D()

const makeNoise = (x: number, y: number) => noise2D(x, y)

const mapNoise = (n, start1, end1, start2, end2) => {
  return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2
}

const createPoints = () => {
  const points = [] as Point[]
  // how many points do we need
  const numPoints = 6
  // used to equally space each point around the circle
  const angleStep = (Math.PI * 2) / numPoints
  // the radius of the circle
  const rad = 75

  for (let i = 1; i <= numPoints; i++) {
    // x & y coordinates of the current point
    const theta = i * angleStep

    const x = 100 + Math.cos(theta) * rad
    const y = 100 + Math.sin(theta) * rad

    // store the point's position
    points.push({
      x: x,
      y: y,
      // we need to keep a reference to the point's original point for when we modulate the values later
      originX: x,
      originY: y,
      // more on this in a moment!
      noiseOffsetX: Math.random() * 1000,
      noiseOffsetY: Math.random() * 1000
    })
  }

  return points
}

const setupPath = (path: HTMLElement | null, noiseStepRef: any) => {
  if (!path) return

  const points = createPoints()

  const animate = () => {
    path.setAttribute('d', spline(points, 1, true) as string)

    points.forEach( point => {
      // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
      const nX = makeNoise(point.noiseOffsetX, point.noiseOffsetX)
      const nY = makeNoise(point.noiseOffsetY, point.noiseOffsetY)

      // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
      const x = mapNoise(nX, -1, 1, point.originX - 10, point.originX + 10)
      const y = mapNoise(nY, -1, 1, point.originY - 10, point.originY + 10)

      // update the point's current coordinates
      point.x = x
      point.y = y

      // progress the point's x, y values through "time"
      point.noiseOffsetX += noiseStepRef.current
      point.noiseOffsetY += noiseStepRef.current
    })

    requestAnimationFrame(animate as FrameRequestCallback)
  }

  animate()
}

interface Props {
  onClick?: Function
  floating?: boolean
  glow?: boolean
  hoverable?: boolean
  oneLine?: boolean
}

const Blob = ({ onClick, floating = true, glow = true, hoverable = true, oneLine = false }: Props ) => {
  const blobRef = useRef(null)
  const pathARef = useRef(null)
  const pathBRef = useRef(null)
  const noiseStep = useRef(0.001)

  useEffect(() => {
    setupPath(pathARef.current, noiseStep)
    if (!oneLine) setupPath(pathBRef.current, noiseStep)

    if (hoverable) {
      const blobEl = blobRef.current as unknown as HTMLElement
      blobEl.addEventListener('mouseover', () => noiseStep.current = 0.005)
      blobEl.addEventListener('mouseleave', () => noiseStep.current = 0.001)
    }
  })

  const handleClick = () => onClick?.()

  const classname = classnames(
    'blob',
    { 'blob--floating': floating },
    { 'blob--glow': glow }
  )

  return (
    <div ref={ blobRef } className={ classname }>
      <div className="blob__inner">
        <svg viewBox="0 0 200 200"><path ref={ pathARef } d="" fill="transparent" stroke="white" strokeWidth="1px" vectorEffect="non-scaling-stroke" onClick={ handleClick } /></svg>
        <svg viewBox="0 0 200 200"><path ref={ pathBRef } d="" fill="transparent" stroke="white" strokeWidth="1px" vectorEffect="non-scaling-stroke" onClick={ handleClick } /></svg>
      </div>
    </div>
  )
}

export default memo(Blob)
