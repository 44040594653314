export const stringToHTML = function (str: string): HTMLElement {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return doc.body;
};

// Source: https://stackoverflow.com/questions/10527983/best-way-to-detect-mac-os-x-or-windows-computers-with-javascript-or-jquery
export function isMacintosh() {
  return navigator.platform.indexOf('Mac') > -1;
}

export function isWindows() {
  return navigator.platform.indexOf('Win') > -1;
}
