import { useState, useEffect, useCallback, useRef } from 'react'

import Blob from '../blob/Blob'
import config from 'config'

import './HomeCarousel.scss'

interface Props {
  slides: {
    s3_key: string
    credits: string
  }[]
}

// eslint-disable-next-line react/display-name
const HomeCarousel = ({ slides }: Props) => {
  const [ currentSlide, setCurrentSlide ] = useState(0)

  // const intervalRef = useRef()
  const carouselRef = useRef(null)
  const controlRef = useRef(null)
  const currentSlideRef = useRef(currentSlide)
  currentSlideRef.current = currentSlide

  const nextSlide = () => {
    setCurrentSlide(currentSlideRef.current === slides.length - 1 ? 0 : currentSlideRef.current + 1)
  }

  useEffect(() => {
    setTimeout(() => randomizePosition(), 3000)
    setInterval(() => randomizePosition(), 20000)
    // intervalRef.current = setInterval(() => nextSlide(), 5000) as any
  }, [])

  const handleBlobClick = () => {
    // clearInterval(intervalRef.current)
    nextSlide()
  }

  const getImageUrl = s3_key => {
    return config.urls.BASE_CONTENT_URL + s3_key
  }

  const randomizePosition = () => {
    const el = carouselRef.current as unknown as HTMLElement
    const control = controlRef.current as unknown as HTMLElement

    if (el) {
      const top = Math.floor( Math.random() * (el.clientHeight - 250) ) + 100
      const left = Math.floor( Math.random() * (el.clientWidth - 250) ) + 100

      control.style.transform = `translate3d(${-left}px, ${-top}px, 0)`
    }
  }

  return (
    <section className="home-carousel" ref={ carouselRef }>
      { slides?.map( (slide, i) => (
        <div
          className={ `home-carousel__slide${currentSlide === i ? ' active' : ''}` }
          key={slide.s3_key}
          style={{ backgroundImage: `url(${getImageUrl(slide.s3_key)})` }}
        >
          { slide.credits && <span className="home-carousel__credits">{ slide.credits }</span> }
        </div>
      ) )}
      <div className="home-carousel__control" ref={ controlRef }>
        <div className="home-carousel__control-inner">
          <Blob onClick={ useCallback(handleBlobClick, [slides]) } />
        </div>
      </div>
    </section>
  )
}

export default HomeCarousel
