import { Facets } from 'components/search/SearchFilters'
import { S3File } from 'types/s3File'

import { SET_FILTERS, SET_FILTER, MERGE_STATE, RESTORE_INITIAL, CLEAR } from '../actions/search'

export interface SearchFilters {
  creator: string
  tags: string
  fileType: string
  regions: string
  sortBy: string
  viewTypes: string
}

export interface SearchHit {
  _highlightResult?: any
  id: number | string
  slug?: string
  created_at: number
  updated_at: number
  time_produced: number
  year_produced: number
  status: boolean
  concept_tags: string[]
  keyword_tags: string[]
  regions: string[]
  item_type?: string
  item_subtype?: string
  creators: string[]
  title: string
  subtitle: string
  description: string
  on_homepage?: boolean
  s3_key?: string
  type?: string
  record_type: string
  subitems?: any[]
  image_subitem?: any
  subcollections?: any[]
  thumbnails: {[key: string]: string}
  poster?: string
  objectID: string
  file?: S3File,
  ga_views?: number
}

export interface SearchState {
  initial: SearchHit[]
  searchHits: SearchHit[]
  totalHits: number
  pagination: number
  currentPage: number
  lastSearchString: string
  filesFetched: boolean
  facets: Facets
  filters: SearchFilters
}

export const initialSearchState: SearchState = {
  initial: [],
  searchHits: [],
  totalHits: 0,
  pagination: 20,
  currentPage: 0,
  lastSearchString: '',
  filesFetched: false,
  facets: {},
  filters: {
    creator: '',
    tags: '',
    fileType: '',
    regions: '',
    sortBy: 'newest',
    viewTypes: 'all'
  }
}

export default (state: SearchState = initialSearchState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.merge ? { ...state.filters, ...action.filters } : action.filters
      }
    case SET_FILTER:
      return action.withReset
        ? { ...state, filters: { ...initialSearchState.filters, [action.name]: action.value } }
        : { ...state, filters: { ...state.filters, [action.name]: action.value } }
    case MERGE_STATE:
      return { ...state, ...action.state }
    case RESTORE_INITIAL:
      return { ...state, searchHits: state.initial }
    case CLEAR:
      return { ...initialSearchState }

    default:
      return state
  }
}
