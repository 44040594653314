import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'

import defaultImage from 'images/defaults/Unscharfe_Zeitung.jpg'
import { itemURL, collectionURL } from 'urls'
import { Link } from 'react-router-dom'

import { Highlight } from '../../types/Highlight'
import { S3File } from '../../types/s3File'
import { WP_REST_API_Post } from 'wp-types'
import { APITag } from 'components/metadata/Tags'

import { storyURL } from 'urls'

import { getItemIcon } from 'lib/itemTypeIcons'

import './HighlightItem.scss'
import { SearchConsoleState } from 'reducers/searchConsole'
import config from 'config'

interface Props extends RouteComponentProps {
  data: Highlight | WP_REST_API_Post
  concept_tags: APITag[]
}

const getPosterThumbnail = s3_key => `${config.urls.BASE_CONTENT_URL}${s3_key}.thumbnail960.webp`

const HighlightItem = ({ data, history, concept_tags }: Props) => {
  const isStoryPost = data.type === 'post'
  const isItem = !!data.item_type

  const image = isStoryPost
    ? data.jetpack_featured_media_url as string
    : data.poster ? getPosterThumbnail(data.poster) : (data.file as S3File).url

  const title = isStoryPost ? data.title.rendered : data.title

  let dataTags = data.concept_tags as string[]
  const tags = dataTags.length && !isNaN(parseInt(dataTags[0], 10))
    ? dataTags.map(tag => concept_tags.find(it => `${it.id}` === `${tag}`)?.tag_name)
    : dataTags

  const getItemLink = (id: string) => isItem ? itemURL(id) : collectionURL(id)

  const link = isStoryPost ? storyURL(data.slug) : getItemLink(data.id as string)

  let summary = ''

  if (isStoryPost) {
    let [categoriesTerm]: any = data._embedded?.['wp:term'] || [[], []]

    const authors = categoriesTerm
      .filter((category) => category.link.includes('/authors/'))
      .map((author) => author.name)

    summary = authors.join(', ')
  } else {
    summary += data.type || data.item_type || ''

    const collectionsNumber = (data.collections as [])?.length
    if (collectionsNumber) summary += `, ${collectionsNumber} collection${collectionsNumber > 1 ? 's' : ''}`

    const itemsNumber = (data.items as [])?.length
    if (itemsNumber) summary += `, ${itemsNumber} item${itemsNumber > 1 ? 's' : ''}`
  }

  return (
    <div className="highlight-item">
      <div className="highlight-item__image">
        <Link to={ link }>
          <img src={ image || defaultImage } />
          { getItemIcon(isStoryPost ? 'Journey' : data.item_type || 'Collection') }
        </Link>
      </div>
      <h3 className="highlight-item__title">
        <Link to={ link } dangerouslySetInnerHTML={{ __html: title }} />
      </h3>
      <div className="highlight-item__summary">{ summary }</div>
      { tags?.length ?
        <div className="highlight-item__tags" style={{marginTop: 10, marginBottom: 10}}>
          { tags.map(tag => (
            <Button
              className="page-link tag d-inline-block"
              style={{ padding: 0, marginBottom: 10 }}
              key={ tag }
              onClick={ () => history.push('/search', { filters: { tags: tag } }) }
            >
              #{ tag }
            </Button>
          )) }
        </div>
        : null
      }
    </div>
  )
}

const mapStateToProps = (state: { searchConsole: SearchConsoleState }) => ({
  concept_tags: state.searchConsole.concept_tags
})

export default withRouter(connect(mapStateToProps, {})(HighlightItem))